import React, { forwardRef } from "react";
import { Callout } from "components/Callout";
import { CTA } from "components/CTA";

// Style
import "./index.scss";

export const Specs = forwardRef((props, ref) => {
	return (
		<section className="specs" ref={ref}>
			<Callout header={"Tech Specs"} />
			<div className="specs-table-container">
				<table className="specs-table">
					<thead>
						<tr>
							<th>Hardware</th>
						</tr>
					</thead>
					<tbody>
						<tr className="spec-list">
							<td className="spec-label">Compatible VR Headsets</td>
							<td className="spec-item">
								Varjo Aero –{" "}
								<a href="https://varjo.com/products/aero/#techspecs">specs</a>
								<br />
								Varjo XR-3 –{" "}
								<a href="https://varjo.com/products/varjo-xr-3/">specs</a>
							</td>
						</tr>
						<tr className="spec-list">
							<td className="spec-label">Physiological Sensors</td>
							<td className="spec-item">
								EEG - 10 channels dry, soft-polymer active electrodes
								<br />
								EMG - 4 channels
								<br />
								EXG - 2 channels
								<br />
								EOG - 2 channels
								<br />
								EDA - 1 channel forehead sensor
								<br />
								PPG - 1 ear clip, Red and IR channels
								<br />
								(Click{" "}
								<a href="https://docs.galea.co/faq/hardware-specs/">here</a> for
								sampling rates)
							</td>
						</tr>
						<tr className="spec-list">
							<td className="spec-label">IMU and Magnetometer</td>
							<td className="spec-item">
								IMU - 6-axis (acceleration and gyroscope)
								<br />
								Magnetometer - 3-axis
								<br />
								(Click{" "}
								<a href="https://docs.galea.co/faq/hardware-specs/">here</a> for
								sampling rates)
							</td>
						</tr>

						<tr className="spec-list">
							<td className="spec-label">Image-based eye tracking</td>
							<td className="spec-item">
								200 Hz with sub-degree accuracy; 1-dot calibration for foveated
								rendering
							</td>
						</tr>
						<tr className="spec-list">
							<td className="spec-label">Data Transmission</td>
							<td className="spec-item">WiFi</td>
						</tr>
						<tr className="spec-list">
							<td className="spec-label">Power Supply</td>
							<td className="spec-item">
								2000 mAh, USB Rechargeable 7-Hour Battery
							</td>
						</tr>
						<tr className="spec-list">
							<td className="spec-label">Positional Tracking</td>
							<td className="spec-item">SteamVR™ 2.0/1.0</td>
						</tr>
					</tbody>
				</table>
				<table className="specs-table">
					<thead>
						<tr>
							<th>Software</th>
						</tr>
					</thead>
					<tbody>
						<tr className="spec-list">
							<td className="spec-label">Graphical User Interface</td>
							<td className="spec-item">
								Galea GUI for data stream visualization, hardware configuration,
								and signal quality assessment.
							</td>
						</tr>
						<tr className="spec-list">
							<td className="spec-label">Galea SDK</td>
							<td className="spec-item">
								Raw data, signal processing, & filtering for all sensors via{" "}
								<a href="https://brainflow.org/">BrainFlow</a>. Support for
								Python, C++, Java, C# and support for Julia, Matlab, and R.
							</td>
						</tr>
						<tr className="spec-list">
							<td className="spec-label">Game Engine SDK</td>
							<td className="spec-item">
								Access all sensor data within Unity and Unreal. Includes example
								code and demo projects.
							</td>
						</tr>
						<tr className="spec-list">
							<td className="spec-label">Streaming</td>
							<td className="spec-item">
								Supports <a href="https://docs.openbci.com/Software/OpenBCISoftware/GUIWidgets/#lsl">LSL</a>, <a href="https://docs.openbci.com/Software/OpenBCISoftware/GUIWidgets/#udp">UDP</a>, and <a href="https://docs.openbci.com/Software/OpenBCISoftware/GUIWidgets/#osc">OSC</a> streaming formats.
							</td>
						</tr>
						<tr className="spec-list">
							<td className="spec-label">
								<a href="https://varjo.com/use-center/get-to-know-your-headset/using-varjo-base/">
									Varjo Base
								</a>
							</td>
							<td className="spec-item">
								Software for powering the Varjo Headsets. Quickly access
								settings, presentation tools, and control your project in
								real-time.
							</td>
						</tr>
						<tr className="spec-list">
							<td className="spec-label">
								<a href="https://developer.varjo.com/">Varjo Developer SDKs</a>
							</td>
							<td className="spec-item">
								Robust access to{" "}
								<a href="https://developer.varjo.com/docs/native/eye-tracking">
									eye tracking
								</a>{" "}
								capabilities and data including pupilometry.
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<CTA text="Reserve Now" link="https://shop.galea.co" />
		</section>
	);
});
